import { Card, Col, Row, Button, Table, Select, Tag, Pagination } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';
import { TradesList } from '../../../utils/data';
import { Helpers } from '../../../utils/helpers';
import { ShowTradeComponent } from '../../../components/site/show-trade';
import { useEffect, useState } from 'react';
import { Emitter } from '../../../utils/emitter';
import { ApiRoutes } from '../../../utils/services/api/routes';
import { CreateContractComponent } from '../../../components/site/create-contract';
import { ShowContractComponent } from '../../../components/site/show-contract';

export const SiteContractPage = () => {

    const columns = [
        {
            title: 'Data/hora',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (text: string) => <div>{Helpers.moment(text).format(Helpers.DateFormat)}</div>,
        },
        {
            title: 'Price',
            key: 'fxRate',
            render: (item: any) => Helpers.addFee(Number(item.fxRate)).toFixed(4),
        },
        {
            title: 'External',
            key: 'externalValue',
            render: (item: any) => Helpers.handleCurrency(item.externalValue, 'USD'),
        },
        {
            title: 'Internal',
            key: 'internalValue',
            render: (item: any) => Helpers.handleCurrency(Helpers.addFee(Number(item.internalValue)), 'BRL'),
        },
        {
            title: 'Status',
            key: 'status',
            render: (item: any) => (Helpers.render.contractStatus(item)),
        },
    ];

    const [data, setData] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalRegisters, setTotalRegisters] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [queryString, setQueryString] = useState<string>('');
    const [perPage, setPerPage] = useState<number>(0);
    const [loadingTrades, setLoadingTrades] = useState<boolean>(false);

    const getContracts = async (pageInformed: number, queryStringInformed = '') => {
        setLoadingTrades(true);
        setData([])

        return await ApiRoutes.topazio
            .getContracts(pageInformed, queryStringInformed)
            .then(async (response: any) => {
                if (!response?.data?.resultSet) return false;

                setData(response.data.resultSet)
                setPerPage(response.data.perPage)
                setTotalPages(response.data.totalPages)
                setTotalRegisters(response.data.totalRegisters)
            })
            .finally(() => setLoadingTrades(false))
    };

    useEffect(() => {
        getContracts(1);

        Emitter.EventEmitter.addListener(Emitter.Event.Action.GetContracts, () => getContracts(currentPage));
    }, [])

    const openContractModal = (record: any, rowIndex: any) => {
        Emitter.EventEmitter.emit(Emitter.Event.Action.OpenModalShowContract, {
            visible: true,
            data: record
        });
    }

    const handleChangeStatus = (value: string) => {
        const tempQueryString = (value !== null) ? `status=${value}` : '';
        setCurrentPage(1)
        setQueryString(tempQueryString)
        getContracts(1, tempQueryString)
    };

    const handlePageChange = (pageInformed: any) => {
        setCurrentPage(pageInformed)
        getContracts(pageInformed, queryString)
    }

    return (
        <>
            <div className='content-normal'>
                <Row className='margin-bottom-md'>
                    <Col sm={24} lg={24}>
                        <CreateContractComponent />
                    </Col>
                </Row>
                <Row>
                    <Col sm={24} lg={24}>
                        <Card title={
                            <div>Histórico de Boletos</div>
                        }>
                            <Row>
                                <Col span={24} md={8} lg={4}>
                                    <div className='margin-bottom-lg'>
                                        <div className='input-label'>Status:</div>
                                        <Select
                                            defaultValue={null}
                                            style={{ width: '100%' }}
                                            onChange={handleChangeStatus}
                                            options={[
                                                { value: null, label: 'Todos' },
                                                { value: 'I', label: 'Solicitado' },
                                                { value: 'A', label: 'Aguardando envio ao change' },
                                                { value: 'N', label: 'Erro ao enviar para o change' },
                                                { value: 'R', label: 'Enviado ao change' },
                                                { value: 'F', label: 'Fechado' },
                                                { value: 'M', label: 'Pagamento pendente' },
                                                { value: 'C', label: 'Cotado' },
                                                { value: 'E', label: 'Efetivado' },
                                                { value: 'L', label: 'Pendente' },
                                                { value: 'H', label: 'Pendente Contábil' },
                                                { value: 'T', label: 'Condições' },
                                                { value: 'S', label: 'Solicitada cotação' },
                                                { value: 'P', label: 'Perdida' },
                                                { value: 'D', label: 'Anulada' },
                                                { value: 'B', label: 'Em edição' },
                                                { value: 'Z', label: 'Cancelado' },
                                            ]}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <div style={{ overflowX: 'auto' }}>
                                <Table
                                    columns={columns}
                                    loading={loadingTrades}
                                    dataSource={data.map((item: any, index: number) => ({ ...item, key: index }))}
                                    pagination={false}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: (event) => openContractModal(record, rowIndex),
                                        };
                                    }}
                                />
                            </div>
                            <Row justify={'center'} className='margin-top-lg'>
                                <Col>
                                    <Pagination
                                        current={currentPage}
                                        responsive={true}
                                        pageSize={perPage}
                                        total={totalRegisters}
                                        showSizeChanger={false}
                                        onChange={handlePageChange}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
            <ShowContractComponent />
        </>
    )
}