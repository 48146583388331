import React, { useEffect, useState } from 'react';
import { Modal, Input, Row, Col, Button, Table, DatePicker, Spin, Select } from 'antd';
import { TradesColumns } from '../../../pages/site/home';
import { ApiRoutes } from '../../../utils/services/api/routes';
import { Emitter } from '../../../utils/emitter';
import { NotificationTypeEnum, showNotification } from '../../notification';
import dayjs from 'dayjs';
import { Helpers } from '../../../utils/helpers';
import { LoadingOutlined } from '@ant-design/icons';
import { ContractPartners, IContractPreview } from '../../../utils/data';

export const CreateContractComponent = () => {
    const [visible, setVisible] = useState(false);
    const [dataContractPreview, setDataContractPreview] = useState<any>(null);
    const [formData, setFormData] = useState<IContractPreview>(ContractPartners.stillman);

    // const [dataTrades, setDataTrades] = useState<any>([]);
    const [loadingContractPreview, setLoadingContractPreview] = useState<boolean>(false);

    const [createdDate, setCreatedDate] = useState<any>(dayjs());

    const getContractPreview = async (value: any) => {
        const dateFormated = Helpers.dayjsInit(value).format(Helpers.InternationalDateFormat);

        setLoadingContractPreview(true);
        setFormData((prevFormData) => ({
            ...prevFormData,
            tradeIds: [],
            averageBankFxRate: 0,
        }));
        setDataContractPreview(null)

        return await ApiRoutes.topazio
            .getContractPreview(dateFormated)
            .then(async (response: any) => {
                if (!response?.data?.resultSet) return false;

                const { resultSet } = response.data;
                console.log({ resultSet })
                const tradeIds = resultSet?.contract?.USD?.trades.map((item: any, index: number) => (item.id))
                setDataContractPreview(resultSet)
                setSelectedRowKeys(tradeIds)
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    tradeIds,
                    averageBankFxRate: resultSet.averageFxRate,
                }));
            })
            .finally(() => setLoadingContractPreview(false))
    };


    const showModal = () => {
        setVisible(true);
        getContractPreview(createdDate);
    };

    const handleOk = () => {
        // Handle submit logic here
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const handleChange = (e: any) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
    const onSelectChange = (newSelectedRowKeys: any[]) => {
        console.log(newSelectedRowKeys);
        setFormData((prevFormData: any) => ({
            ...prevFormData,
            tradeIds: newSelectedRowKeys,
        }));
    };

    const [loading, setLoading] = useState(false);
    const handleFormSubmit = async () => {
        setLoading(true)

        const { averageBankFxRate, averageFxRate, averageSpot, intermBankAba, intermBankCity, intermBankName, intermBankSwift, clientReference, ...body } = formData;

        await ApiRoutes.topazio
            .createContract(body)
            .then(async (response: any) => {
                const contractId = response?.data?.resultSet?.id;

                if (!contractId) return;

                showNotification({
                    type: NotificationTypeEnum.success,
                    title: 'Boleto criado!',
                    message: "",
                    duration: 10,
                });

                Emitter.EventEmitter.emit(Emitter.Event.Action.GetContracts, true);

                setVisible(false);
            })
            .finally(() => setLoading(false))
    };


    const handleChangePartner = (value: string) => {
        setFormData(ContractPartners[value])
    };
    
    return (
        <>
            <Button type="primary" onClick={showModal}>
                Criar Contrato
            </Button>
            <Modal
                open={visible}
                centered={true}
                className='modal-large'
                title="Modal para criar contrato"
                onOk={handleOk}
                key={'modal_create_contract'}
                onCancel={handleCancel}
                width={1000}
                destroyOnClose
                footer={[
                    <>
                        {dataContractPreview?.averageFxRate && <>
                            <Button key="cancel_contract" onClick={handleCancel}>
                                Cancelar
                            </Button>
                            <Button
                                key="submit_contract"
                                type="primary"
                                loading={loading}
                                onClick={handleFormSubmit}
                            >
                                Gerar Boleto
                            </Button>
                        </>}
                    </>

                ]}
            >
                <Row gutter={16} justify={'center'}>
                    <Col xs={24} md={12} lg={6} className='contract-input text-center'>
                        <label>Data</label>
                        <DatePicker format={Helpers.OnlyDateFormat} defaultValue={createdDate} onChange={getContractPreview} />
                    </Col>
                    <Col xs={24} md={12} lg={6}>
                        <div className='contract-input text-center'>
                            <div className='input-labels'>Parceiro</div>
                            <Select
                                defaultValue={'stillman'}
                                style={{ width: '100%' }}
                                onChange={handleChangePartner}
                                options={[
                                    { value: 'stillman', label: 'Stillman' },
                                ]}
                            />
                        </div>
                    </Col>
                    {
                        loadingContractPreview && <Col span={24} className='text-center margin-top-medium'>
                            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                        </Col>
                    }
                </Row>

                {dataContractPreview?.averageFxRate && <>
                    <Row gutter={16}>
                        <Col span={12} className='contract-input'>
                            <label>External Name:</label>
                            <Input
                                name="externalName"
                                value={formData.externalName}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={12} className='contract-input'>
                            <label>External IBAN:</label>
                            <Input
                                name="externalIban"
                                value={formData.externalIban}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={12} className='contract-input'>
                            <label>External Address:</label>
                            <Input
                                name="externalAddress"
                                value={formData.externalAddress}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={12} className='contract-input'>
                            <label>Intermediary Bank SWIFT:</label>
                            <Input
                                name="intermBankSwift"
                                value={formData.intermBankSwift}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={12} className='contract-input'>
                            <label>Intermediary Bank City:</label>

                            <Input
                                name="intermBankCity"
                                value={formData.intermBankCity}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={12} className='contract-input'>
                            <label>Intermediary Bank Name:</label>

                            <Input
                                name="intermBankName"
                                value={formData.intermBankName}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={12} className='contract-input'>
                            <label>Intermediary Bank ABA:</label>

                            <Input
                                name="intermBankAba"
                                value={formData.intermBankAba}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={12} className='contract-input'>
                            <label>Receiver Bank SWIFT:</label>

                            <Input
                                name="receiverBankSwift"
                                value={formData.receiverBankSwift}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={12} className='contract-input'>
                            <label>Receiver Bank City:</label>

                            <Input
                                name="receiverBankCity"
                                value={formData.receiverBankCity}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={12} className='contract-input'>
                            <label>Receiver Bank ABA:</label>

                            <Input
                                name="receiverBankAba"
                                value={formData.receiverBankAba}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={12} className='contract-input'>
                            <label>Receiver Bank Name:</label>
                            <Input
                                name="receiverBankName"
                                value={formData.receiverBankName}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12} className='contract-input'>
                            <label>Nature:</label>
                            <Input
                                name="nature"
                                value={formData.nature}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={12} className='contract-input'>
                            <label>Country:</label>
                            <Input
                                name="country"
                                value={formData.country}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12} className='contract-input'>
                            <label>Average Bank FX Rate:</label>
                            <Input
                                name="averageBankFxRate"
                                value={formData.averageBankFxRate}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={12} className='contract-input'>
                            <label>Average FX Rate:</label>
                            <Input
                                name="averageFxRate"
                                value={formData.averageFxRate}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12} className='contract-input'>
                            <label>Average Spot:</label>
                            <Input
                                name="averageSpot"
                                value={formData.averageSpot}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col span={12} className='contract-input'>
                            <label>Client Reference:</label>
                            <Input
                                name="clientReference"
                                value={formData.clientReference}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>
                    <Row className='margin-top-lg'>
                        <Col span={24}>
                            <div style={{ overflowX: 'auto' }}>
                                <Table
                                    rowSelection={{
                                        selectedRowKeys: formData.tradeIds,
                                        onChange: onSelectChange,
                                    }}
                                    columns={TradesColumns}
                                    dataSource={dataContractPreview?.contract?.USD?.trades.map((item: any, index: number) => ({ ...item, key: item.id }))}
                                    pagination={false}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            // onClick: (event) => openTradeEditModal(record, rowIndex),
                                        };
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                </>}
            </Modal>
        </>
    );
};
