export const TradesList = {
    "resultSet": [
        {
            "id": "dd377fb5-016e-4292-adbd-b0b0a5a353d9",
            "status": 0,
            "partnerId": "49786191000158",
            "partner": "MAZZERA",
            "partnerType": "COMERCIAL",
            "currencyId": 220,
            "internalSettlementDate": "2023-06-29T03:00:00.000Z",
            "externalSettlementDate": "2023-06-29T03:00:00.000Z",
            "operation": "INBOUND",
            "currency": "USD",
            "quotationId": "649dbe6afa6a625a314feec9",
            "fxRate": 4.8435,
            "externalValue": 10000,
            "internalValue": 48435,
            "expiredDate": "2023-06-29T17:29:59.000Z",
            "createdDate": "2023-06-29T17:24:59.000Z",
            "lastAuthorizedBy": 2,
            "lastAuthorizedUser": "TOPAZIO CAMBIO TI",
            "partnerOrderId": null,
            "origin": "API"
        },
        {
            "id": "da37fa58-4e46-4d83-8957-5196cab2ee1b",
            "status": 0,
            "partnerId": "49786191000158",
            "partner": "MAZZERA",
            "partnerType": "COMERCIAL",
            "currencyId": 220,
            "internalSettlementDate": "2023-06-29T03:00:00.000Z",
            "externalSettlementDate": "2023-06-29T03:00:00.000Z",
            "operation": "INBOUND",
            "currency": "USD",
            "quotationId": "649dbe91fa6a625a314fef34",
            "fxRate": 4.844,
            "externalValue": 10000,
            "internalValue": 48440,
            "expiredDate": "2023-06-29T17:30:38.000Z",
            "createdDate": "2023-06-29T17:25:38.000Z",
            "lastAuthorizedBy": 2,
            "lastAuthorizedUser": "TOPAZIO CAMBIO TI",
            "partnerOrderId": null,
            "origin": "API"
        },
        {
            "id": "eb56c92a-06d5-4d3b-b797-da291a9a54fe",
            "status": 0,
            "partnerId": "49786191000158",
            "partner": "MAZZERA",
            "partnerType": "COMERCIAL",
            "currencyId": 220,
            "internalSettlementDate": "2023-06-29T03:00:00.000Z",
            "externalSettlementDate": "2023-06-29T03:00:00.000Z",
            "operation": "INBOUND",
            "currency": "USD",
            "quotationId": "649dbeb4c083f2d6a190c0c1",
            "fxRate": 4.8435,
            "externalValue": 10000,
            "internalValue": 48435,
            "expiredDate": "2023-06-29T17:31:12.000Z",
            "createdDate": "2023-06-29T17:26:12.000Z",
            "lastAuthorizedBy": 2,
            "lastAuthorizedUser": "TOPAZIO CAMBIO TI",
            "partnerOrderId": null,
            "origin": "API"
        },
        {
            "id": "90e625cd-210d-4382-a7ba-19eaeaa4c419",
            "status": 0,
            "partnerId": "49786191000158",
            "partner": "MAZZERA",
            "partnerType": "COMERCIAL",
            "currencyId": 220,
            "internalSettlementDate": "2023-06-29T03:00:00.000Z",
            "externalSettlementDate": "2023-06-29T03:00:00.000Z",
            "operation": "INBOUND",
            "currency": "USD",
            "quotationId": "649dbec6c083f2d6a190c0f1",
            "fxRate": 4.843,
            "externalValue": 10000,
            "internalValue": 48430,
            "expiredDate": "2023-06-29T17:31:30.000Z",
            "createdDate": "2023-06-29T17:26:30.000Z",
            "lastAuthorizedBy": 2,
            "lastAuthorizedUser": "TOPAZIO CAMBIO TI",
            "partnerOrderId": null,
            "origin": "API"
        },
        {
            "id": "60db5679-23a0-4a5c-82f5-c87e9eee7428",
            "status": 0,
            "partnerId": "49786191000158",
            "partner": "MAZZERA",
            "partnerType": "COMERCIAL",
            "currencyId": 220,
            "internalSettlementDate": "2023-06-29T03:00:00.000Z",
            "externalSettlementDate": "2023-06-29T03:00:00.000Z",
            "operation": "INBOUND",
            "currency": "USD",
            "quotationId": "649dbefa8e3a97577f51d2a5",
            "fxRate": 4.842,
            "externalValue": 10000,
            "internalValue": 48420,
            "expiredDate": "2023-06-29T17:32:23.000Z",
            "createdDate": "2023-06-29T17:27:23.000Z",
            "lastAuthorizedBy": 2,
            "lastAuthorizedUser": "TOPAZIO CAMBIO TI",
            "partnerOrderId": null,
            "origin": "API"
        }
    ],
    "page": 1,
    "perPage": 50,
    "totalRegisters": 5,
    "totalPages": 1
}

export type IContractPreview = {
    tradeIds: string[];
    externalName: string;
    externalIban: string;
    externalAddress: string;
    intermBankSwift: string;
    intermBankCity: string;
    intermBankName: string;
    intermBankAba: string;
    receiverBankSwift: string;
    receiverBankCity: string;
    receiverBankAba: string;
    receiverBankName: string;
    nature: number;
    country: number;
    averageBankFxRate: number;
    averageFxRate: number;
    averageSpot: number;
    clientReference: string;
};


export const ContractPartners: any = {
    stillman: {
        tradeIds: [],
        externalName: 'Stillman Digital LLC',
        externalIban: '1466109',
        externalAddress: '400 NW 1st Ave, Unit 4204, Miami FL 33128',
        intermBankSwift: 'WFBIUS6S',
        intermBankCity: 'Phoenixville',
        intermBankName: 'Wells Fargo Bank',
        intermBankAba: '121000248',
        receiverBankSwift: 'CUESUS33',
        receiverBankCity: 'Phoenixville, PA',
        receiverBankAba: '031302971',
        receiverBankName: 'CUSTOMERS BANK',
        nature: 121860900590,
        country: 2496,
        averageBankFxRate: 1.23,
        averageFxRate: 4.56,
        averageSpot: 7.89,
        clientReference: 'MAZZERA S.A',
    }
}