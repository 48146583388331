import dayjs from "dayjs";
import moment from 'moment-timezone';
import momentBusinessDays from 'moment-business-days';
import 'dayjs/locale/en';
import { Tag } from "antd";

dayjs.locale('en');

export const Helpers = {
    HourAndMinuteFormat: 'HH:mm',
    DateFormat: 'DD/MM/YYYY - HH:mm',
    DateFormatWithSeconds: 'DD/MM/YYYY - HH:mm:ss',
    OnlyDateFormat: 'DD/MM/YYYY',
    InternationalDateFormat: 'YYYY-MM-DD',
    currencyHandle: new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    }),
    handleCurrency: (value: number, currency: string) => {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency,
            currencyDisplay: 'narrowSymbol'
        }).format(value)
    },
    moment: moment,
    isWeekend(date: any) {
        return date.day() === 0 || date.day() === 6;
    },
    getNextBusinessDay(days: number) {
        const today = Helpers.moment().add(Number(days || 0), 'days');

        while (Helpers.isWeekend(today)) {
            today.add(days, 'days');
        }

        return today;
    },
    moment_business: () => {
        momentBusinessDays.locale('pt-br');
        return momentBusinessDays;
    },
    dayjsInit: dayjs,
    addFee: (value: number, spread = 0.45) => {
        var percentage = spread / 100;
        var increase = value * percentage;

        return Number(value + increase);
    },
    render: {
        contractStatus: (item: any) => {
            return (<>
                {item.status === 'I' && <Tag color="#2db7f5">Solicitado</Tag>}
                {item.status === 'A' && <Tag color="#2db7f5">Aguardando envio ao change</Tag>}
                {item.status === 'N' && <Tag color="#BF40BF">Erro ao enviar para o change</Tag>}
                {item.status === 'R' && <Tag color="#108ee9">Enviado ao change</Tag>}
                {item.status === 'F' && <Tag color="#56c1aa">Fechado</Tag>}
                {item.status === 'M' && <Tag color="#F9AA4B">Pagamento pendente</Tag>}
                {item.status === 'C' && <Tag color="#F9AA4B">Cotado</Tag>}
                {item.status === 'E' && <Tag color="##17D4B3">Efetivado</Tag>}
                {item.status === 'L' && <Tag color="#F9AA4B">Pendente</Tag>}
                {item.status === 'H' && <Tag color="#F9AA4B">Pendente Contábil</Tag>}
                {item.status === 'T' && <Tag color="#F9AA4B">Condições</Tag>}
                {item.status === 'S' && <Tag color="#F9AA4B">Solicitada cotação</Tag>}
                {item.status === 'P' && <Tag color="#cd201f">Perdida</Tag>}
                {item.status === 'D' && <Tag color="#cd201f">Anulada</Tag>}
                {item.status === 'B' && <Tag color="#F9AA4B">Em edição</Tag>}
                {item.status === 'Z' && <Tag color="#cd201f">Cancelado</Tag>}
            </>)
        }
    }
}