import { Api } from ".";
import axios from "axios";

export const ApiRoutes = {
  authentication: {
    async login(data: any) {
      return Api.request({
        authenticated: false,
        method: "POST",
        path: "/api/authentication/login",
        data,
      });
    },
  },
  topazio: {
    async getWebhook(tradeId: string) {
      return Api.request({
        authenticated: false,
        method: "GET",
        path: `/api/topazio/webhook/${tradeId}`,
      });
    },
    async acceptTrade(id: string, quotationId: string, data: any) {
      return Api.request({
        authenticated: true,
        method: "PUT",
        path: `/api/topazio/trade/${id}`,
        data,
      });
    },
    async createTrade(data: any) {
      return Api.request({
        authenticated: true,
        method: "POST",
        path: "/api/topazio/trade",
        data,
      });
    },
    async getTrades(page: number, queryString: string = "") {
      return Api.request({
        authenticated: true,
        method: "GET",
        path: `/api/topazio/trades?page=${page}${
          queryString ? "&".concat(queryString) : ""
        }`,
      });
    },
    async getTrade(id: string) {
      return Api.request({
        authenticated: true,
        method: "GET",
        path: `/api/topazio/trades?page=1&id=${id}`,
      });
    },
    async deleteTrade(id: string) {
      return Api.request({
        authenticated: true,
        method: "DELETE",
        path: `/api/topazio/trade/${id}`,
      });
    },
    async getContracts(page: number, queryString: string = "") {
      return Api.request({
        authenticated: true,
        method: "GET",
        path: `/api/topazio/contracts?page=${page}${
          queryString ? "&".concat(queryString) : ""
        }`,
      });
    },
    async getContractPreview(createdDate: string) {
      return Api.request({
        authenticated: true,
        method: "GET",
        path: `/api/topazio/contract-preview?createdDate=${createdDate}`,
      });
    },
    async createContract(data: any) {
      return Api.request({
        authenticated: true,
        method: "POST",
        path: "/api/topazio/contract",
        data,
      });
    },
  },
  stillman: {
    async tradingRate(data: any) {
      return Api.request({
        authenticated: true,
        method: "POST",
        path: "/api/partner/stillman/trading/rate",
        data,
      });
    },
  },
  fastforex: {
    async fetchOne() {
      return axios
        .request({
          method: "GET",
          baseURL:
            "https://api.fastforex.io/fetch-one?from=USD&to=BRL&api_key=0cdd089f42-04db9548fc-rsek8l",
        })
        .then((response: any) => {
          return response?.data?.result?.BRL;
        });
    },
  },
};
