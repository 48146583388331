import { Card, Col, Row, Button, Table, Select, Tag, Pagination, DatePicker } from 'antd';
import { WhatsAppOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { CreateOrderComponent } from '../../../components/site/create-order';
import { TradesList } from '../../../utils/data';
import { Helpers } from '../../../utils/helpers';
import { ShowTradeComponent } from '../../../components/site/show-trade';
import { useEffect, useState } from 'react';
import { Emitter } from '../../../utils/emitter';
import { ApiRoutes } from '../../../utils/services/api/routes';

export const TradesColumns = [
    {
        title: 'Data/hora',
        dataIndex: 'createdDate',
        key: 'createdDate',
        render: (text: string) => <div>{Helpers.moment(text).format(Helpers.DateFormat)}</div>,
    },
    {
        title: 'Price',
        key: 'fxRate',
        render: (item: any) => Helpers.addFee(Number(item.fxRate)).toFixed(4),
    },
    {
        title: 'External',
        key: 'externalValue',
        render: (item: any) => Helpers.handleCurrency(item.externalValue, item.currency),
    },
    {
        title: 'Internal',
        key: 'internalValue',
        render: (item: any) => Helpers.handleCurrency(Helpers.addFee(Number(item.internalValue)), 'BRL'),
    },
    {
        title: 'Status',
        key: 'status',
        render: (item: any) => (<>
            {Number(item.status) === 0 && <Tag color="#ff5454">Encerrado</Tag>}
            {Number(item.status) === 1 && <Tag color="#F9AA4B">Em Aberto</Tag>}
            {Number(item.status) === 2 && <Tag color="#BF40BF">Aceito</Tag>}
            {Number(item.status) === 4 && <Tag color="#108ee9">Aprovado pela tesouraria</Tag>}
            {Number(item.status) === 5 && <Tag color="#56c1aa">Boletado</Tag>}
            {Number(item.status) === 6 && <Tag color="#17D4B3">Efetivado</Tag>}
        </>),
    }
];

export const SiteHomePage = () => {
    const [data, setData] = useState<any>([]);
    const [trades, setTrades] = useState<any>({});
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalRegisters, setTotalRegisters] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [queryString, setQueryString] = useState<string>('');
    const [perPage, setPerPage] = useState<number>(0);
    const [loadingTrades, setLoadingTrades] = useState<boolean>(false);
    const [sumTrades, setSumTrades] = useState<number>(0);

    const getTrades = async (pageInformed: number, queryStringInformed = '') => {
        setLoadingTrades(true);
        setData([])
        setTrades({})
        setSumTrades(0)

        return await ApiRoutes.topazio
            .getTrades(pageInformed, queryStringInformed)
            .then(async (response: any) => {
                if (!response?.data?.resultSet) return false;
                setData(response.data.resultSet)
                setTrades(response.data.trades)
                setPerPage(response.data.perPage)
                setTotalPages(response.data.totalPages)
                setTotalRegisters(response.data.totalRegisters)
            })
            .finally(() => setLoadingTrades(false))
    };

    useEffect(() => {
        getTrades(1);

        Emitter.EventEmitter.addListener(Emitter.Event.Action.GetTrades, () => getTrades(currentPage));
    }, [])

    useEffect(() => {
        let sumData = data.map((item: any) => Helpers.addFee(item.internalValue))
        let sumTrade = sumData.reduce((acum: any, element: any) => acum + element, 0)
            setSumTrades(sumTrade)
    }, [data])

    const openTradeEditModal = (record: any, rowIndex: any) => {
        const providerAssetValue = (trades[record.id]?.provider_asset?.price) ? trades[record.id]?.provider_asset?.price : 1;
        Emitter.EventEmitter.emit(Emitter.Event.Action.OpenModalShowTrade, {
            visible: true,
            data: { ...record, providerAssetValue }
        });
    }


    const handlePageChange = (pageInformed: any) => {
        setCurrentPage(pageInformed)
        getTrades(pageInformed, queryString)
    }

    const [filter, setFilter] = useState<any>({
        startedDate: dayjs(),
        endDate: dayjs(),
        status: null,
    });

    const filterByInputs = async (name: string, value: any) => {
        let filterModified = filter;

        filterModified[name] = value;

        setFilter(filterModified);

        const startedDateFormated = Helpers.dayjsInit(filterModified.startedDate).format(Helpers.InternationalDateFormat);
        const endDateFormated = Helpers.dayjsInit(filterModified.endDate).format(Helpers.InternationalDateFormat);

        const tempQueryString = (filterModified.status !== null) ? `status=${filterModified.status}&startDate=${startedDateFormated}&endDate=${endDateFormated}` : `startDate=${startedDateFormated}&endDate=${endDateFormated}`;

        setCurrentPage(1)
        setQueryString(tempQueryString)
        getTrades(1, tempQueryString)

    };

    return (
        <>
            <div className='content-normal'>
                <Row className='margin-bottom-md'>
                    <Col sm={24} lg={24}>
                        <CreateOrderComponent />
                    </Col>
                </Row>
                <Row>
                    <Col sm={24} lg={24}>
                        <Card title={
                            <div>Histórico de Trades</div>
                        }>
                            <Row gutter={16}>
                                <Col span={24} md={8} lg={4}>
                                    <div className='margin-bottom-lg'>
                                        <div className='input-label'>Status:</div>
                                        <Select
                                            defaultValue={filter.status}
                                            style={{ width: '100%' }}
                                            onChange={(e) => filterByInputs('status', e)}
                                            value={filter.status}
                                            options={[
                                                { value: null, label: 'Todos' },
                                                { value: 0, label: 'Encerrado' },
                                                { value: 1, label: 'Em Aberto' },
                                                { value: 2, label: 'Aceito' },
                                                { value: 4, label: 'Aprovado pela tesouraria' },
                                                { value: 5, label: 'Boletado' },
                                            ]}
                                        />
                                    </div>
                                </Col>
                                <Col span={24} md={8} lg={4}>
                                    <div className='margin-bottom-lg'>
                                        <div className='input-label'>Data inicial:</div>
                                        <DatePicker format={Helpers.OnlyDateFormat} defaultValue={filter.startedDate} onChange={(e) => filterByInputs('startedDate', e)} />
                                    </div>
                                </Col>
                                <Col span={24} md={8} lg={4}>
                                    <div className='margin-bottom-lg'>
                                        <div className='input-label'>Data final:</div>
                                        <DatePicker format={Helpers.OnlyDateFormat} defaultValue={filter.endDate} onChange={(e) => filterByInputs('endDate', e)} />
                                    </div>
                                </Col>
                                <Col span={24} md={8} lg={4}>
                                    <div className='margin-bottom-lg'>
                                        <div className='input-label'>Soma total:</div>
                                        <p defaultValue={sumTrades} onChange={(e) => filterByInputs('status', e)} />
                                        <p>{Helpers.handleCurrency(sumTrades, 'BRL')}</p>
                                    </div>
                                </Col>
                            </Row>
                            <div style={{ overflowX: 'auto' }}>
                                <Table
                                    columns={TradesColumns}
                                    loading={loadingTrades}
                                    dataSource={data.map((item: any, index: number) => ({ ...item, key: index }))}
                                    pagination={false}
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: (event) => openTradeEditModal(record, rowIndex)
                                        };
                                    }}
                                />
                            </div>
                            <Row justify={'center'} className='margin-top-lg'>
                                <Col>
                                    <Pagination
                                        current={currentPage}
                                        responsive={true}
                                        pageSize={perPage}
                                        total={totalRegisters}
                                        showSizeChanger={false}
                                        onChange={handlePageChange}
                                    />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </div>
            <ShowTradeComponent />
        </>
    )
}